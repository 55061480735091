import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux'
import Home from './views/Home';
import Tools from './views/Tools';
import Format from './views/Format';
import Verify from './views/Verify';
import Check from './views/Check';
import Alert from './views/Alert';

function Routes(props) {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/tools" exact component={Tools} />
        <Route path="/mid/:markID" exact component={Verify} />
        <Route path="/css/:markID" exact component={Format} />
        <Route path="/check" exact component={Check} />
      </Switch>
      <Alert alert={props.alert} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
	alert: state.alert,
})

const mapDispatchToProps = (dispatch) => ({	
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)

