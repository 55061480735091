import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { Button, TextField, Grid, Link } from "@material-ui/core";
import axios from "axios";
import Crypto from "../Crypto";
import Moment from "moment-timezone"
//import store from "store"
const public_key_id = process.env.REACT_APP_PUBLIC_KEY_ID;

const useStyles = makeStyles(
  (theme) => ({
    submit_form: {
      width: "100%",
    },
    form_container: {
      marginTop: "16px",
    },
    input: {
      margin: "0px",
      padding: "0px !important",
    },
  }),
  { withTheme: true }
);

function Register(props) {
  //const [registeredKey, setRegisteredKey] = React.useState("");
  //const [registryEntry, setRegistryEntry] = React.useState("");
  //  const [markID, setMarkID] = React.useState("");
  //  const [mark, setMark] = React.useState("");

  const classes = useStyles();

  const sub = React.useRef(null);

  const [value, setValue] = React.useState(props.crypto.registry_entry);
  const [changing, setChanging] = React.useState(false);

  var new_crypto = Object.assign({}, props.crypto);

  var setCrypto = props.setCrypto

  React.useEffect(() => {
    if (new_crypto.refresh_entry) {    
      setValue(new_crypto.registry_entry)      
      new_crypto.refresh_entry = false
      setCrypto(new_crypto);
    }
  }, [new_crypto,setCrypto,setValue]);
  
  return (
    <form
      className={classes.submit_form}
      onSubmit={async (e) => {
        e.preventDefault();

        var creation_time = Moment().milliseconds(0).valueOf()

      
        var new_crypto = Object.assign({}, props.crypto);
        new_crypto.registry_entry = sub.current.value;
        props.setCrypto(new_crypto);

        if (!props.crypto.registry_entry || !props.crypto.registry_entry.length) {
          props.setAlert({
            open: true,
            severity: "error",
            message: "cannot submit an empty registry entry",
            vertical: "bottom",
            horizontal: "right",
          });
          return false;
        }

        var ro = {};
        ro.method = "POST";
        ro.url = "/generate_token";
        ro.data = {};
        ro.data["public_key"] = props.crypto.public_key;

        var setup = await axios(ro);
       
        var extract_token_timestamp = await  Crypto
        .verifyServerMessage(setup.data.token, "verify")
        .then(async (result) => {

  

          return result

        })
        

        if (extract_token_timestamp.signed_by === public_key_id) {
          var token_timestamp = extract_token_timestamp.message.split(':::')[1];
        }

        var armored = await Crypto.signMessage(
          process.env.REACT_APP_BRAND+":" + setup.data.hashed_token + ':::' + token_timestamp + "\r\n" + props.crypto.registry_entry,
          false,
          {creation_time: creation_time}
        );
        var armored_only = await Crypto.signMessage(props.crypto.registry_entry, false, {creation_time: creation_time});

        Crypto.verifyMessage(armored, "verify", {creation_time: creation_time})
          .then(async (result) => {
            props.setAlert({
              open: true,
              severity: "success",
              message: "message is properly formatted",
              vertical: "bottom",
              horizontal: "right",
            });

            ro = {};
            ro.method = "POST";
            ro.url = "/register_key";
            ro.data = {};
            ro.data["verify_mark"] = armored;
            ro.data["mark"] = armored_only;
            ro.data["token"] = setup.data.token;
            //ro.data['md5'] = setup.data.md5
            ro.data["hashed_token"] = setup.data.hashed_token;
            ro.data["public_key"] = props.crypto.public_key;

            var marked = await axios(ro);

            var new_crypto = Object.assign({}, props.crypto);
            new_crypto.registeredKey = marked.data.html;
            new_crypto.qr = marked.data.qr;
            new_crypto.mark_id = marked.data.mark_id;
            new_crypto.signedMark = null;
            
            new_crypto.refresh_id = true;
            new_crypto.lookupMark = marked.data.mark_id;
            new_crypto.foundMark = marked.data.mark_id;

            new_crypto.refresh_lookup_key = true;
            new_crypto.lookupKey = props.crypto.public_key;

            
            new_crypto.lookupKeyFound = marked.data.public_key_id.trim()
            new_crypto.lookupKeyFingerprint = marked.data.public_key_fingerprint;

            props.setCrypto(new_crypto);

            var new_temp = Object.assign({}, props.temp);
            new_temp.refresh_key = true;
            props.setTemp(new_temp);
            //setRegisteredKey(marked.data.html);
          })
          .catch((err) => {
            props.setAlert({
              open: true,
              severity: "error",
              message: "message is not properly formatted",
              vertical: "bottom",
              horizontal: "right",
            });
          });
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant={process.env.REACT_APP_STYLE}
            multiline={true}
            rows={5}
            fullWidth
            size="small"
            onChange={(e) => {
              setChanging(true)
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.registry_entry = e.target.value;
              new_crypto.stored = false
              new_crypto.changed = true   
              
              setValue(e.target.value)
              if (window.typing) {                
                clearTimeout(window.typing)
              }
              window.typing = setTimeout(()=>{
                //store.remove('crypto')
                //props.setCrypto(new_crypto)

                props.changeCrypto(async () => {
                  setChanging(false)

                }, new_crypto);

              },100)
              

            }}
            value={value}
            inputRef={sub}
            label="registry entry"
          />
        </Grid>

        <Grid item xs={12}>
          <Button
           color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            disabled={(!props.crypto.registry_entry || !props.crypto.private_key || !props.crypto.public_key || !props.crypto.public_key_id) || (changing) || ((props.crypto.notFound && props.crypto.notFound === 'invalid key')) ? true : false}
          >
            Register Public Key
          </Button>
        </Grid>

        <Grid item xs={12}>
          {props.crypto.public_key_id ? (
            <React.Fragment>
              public key id{" "}
              <Link
                href={
                  "https://" +
                  process.env.REACT_APP_DOMAIN +
                  "/key/" +
                  props.crypto.public_key_id
                }
                target={"_" + props.crypto.public_key_id}
              >
                {props.crypto.public_key_id}
              </Link>
            </React.Fragment>
          ) : null}
          {props.crypto.keyFound ? " registered" : null}
          {props.crypto.notFound
            ? props.crypto.notFound === "invalid key"
              ? "invalid key"
              : " not registered"
            : null}
            {!props.crypto.public_key_id && (!props.crypto.notFound || props.crypto.notFound !== 'invalid key') ? 'no key loaded' : null}
        </Grid>
      </Grid>
    </form>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
