import "./App.css";
import { withRouter, useRouteMatch } from "react-router-dom";
import React from "react";
import Routes from "./Routes";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setTheme, setCrypto, setTemp, setAlert } from "./actions";
import secondaryColor from "@material-ui/core/colors/grey";
import dangerColor from "@material-ui/core/colors/orange";
import errorColor from "@material-ui/core/colors/red";
import { darken } from "@material-ui/core/styles/colorManipulator";
import CssBaseline from "@material-ui/core/CssBaseline";
import axios from "axios";
import Crypto from "./Crypto";
import store from "store";
import Confirm from "./Confirm";

const public_key_id = process.env.REACT_APP_PUBLIC_KEY_ID;

var registry_entry =
  "official " + process.env.REACT_APP_BRAND + " public key registry entry";

const public_key = process.env.REACT_APP_PUBLIC_KEY;

const private_key = process.env.APP_PRIVATE_KEY;

window.typing = null;

var shadows = [];
for (var i = 0; i < 25; i++) {
  shadows[i] = "none";
}

const lightTheme = createMuiTheme({
  shadows: shadows,
  palette: {
    type: "light",
    error: {
      light: errorColor[300],
      main: errorColor[400],
      dark: errorColor[700],
      contrastText: darken(errorColor[500], 0.3),
    },
    danger: {
      light: dangerColor[300],
      main: dangerColor[400],
      dark: dangerColor[700],
      contrastText: darken(dangerColor[500], 0.3),
    },
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
    secondary: {
      light: secondaryColor[300],
      main: secondaryColor[500],
      dark: secondaryColor[600],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    background: {
      default: "#ffffff",
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        backgroundColor: "#f5f5f5",
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: "12px 0 !important",
      },
    },
    MuiAlert: {
      message: {
        fontSize: "14px",
      },
    },
    MuiPaper: {
      root: {
        borderRadius: "0px !important",
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: "0px !important",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "0px !important",
      },
      contained: {
        color: "#ffffff",
      },
      containedPrimary: {
        color: "#ffffff",
      },
      containedSecondary: {
        color: "#ffffff",
      },
    },
    MuiCssBaseline: {
      "@global": {
        ".subSection": {
          backgroundColor: secondaryColor[200],
        },

        "::-webkit-scrollbar": {
          width: "16px",
          height: "16px",
          cursor: "pointer",
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "#dfdfdf",
          cursor: "pointer",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#c0c0c0",
          cursor: "pointer",
        },
        "::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#cfcfcf",
          cursor: "pointer",
        },
        a: {
          color: process.env.REACT_APP_PRIMARY_COLOR,
          textDecoration: "none",
          cursor: "pointer",
          "&&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },
        },
      },
    },
  },
});
const darkTheme = createMuiTheme({
  palette: {
    shadows: shadows,
    type: "dark",
    error: {
      light: errorColor[300],
      main: errorColor[400],
      dark: errorColor[700],
      contrastText: darken(errorColor[500], 0.2),
    },
    danger: {
      light: dangerColor[300],
      main: dangerColor[400],
      dark: dangerColor[700],
      contrastText: darken(dangerColor[500], 0.3),
    },
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR,
    },
    secondary: {
      light: secondaryColor[300],
      main: secondaryColor[600],
      dark: secondaryColor[800],
      contrastText: darken(secondaryColor[700], 0.5),
    },
    background: {
      default: "#191b1c",
    },
  },
  overrides: {
    MuiAccordionSummary: {
      content: {
        margin: "12px 0 !important",
      },
    },
    MuiAlert: {
      message: {
        fontSize: "14px",
      },
    },
    MuiPaper: {
      root: {
        borderRadius: "0px !important",
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: "0px !important",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "0px !important",
      },
      contained: {
        color: "#ffffff",
      },
      containedPrimary: {
        color: "#ffffff",
      },
      containedSecondary: {
        color: "#ffffff",
      },
    },
    MuiCssBaseline: {
      "@global": {
        ".divider": {
          borderColor: "#555555 !important",
        },
        ".subSection": {
          backgroundColor: secondaryColor[700],
        },
        "::-webkit-scrollbar": {
          width: "16px",
          height: "16px",
          cursor: "pointer",
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "#323638",
          cursor: "pointer",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#151515",
          cursor: "pointer",
        },
        "::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#202324",
          cursor: "pointer",
        },

        a: {
          color: process.env.REACT_APP_PRIMARY_COLOR,
          textDecoration: "none",
          "&&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
});

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

var openpgp = null;

function App(props) {
  const [loadedMark, setLoadedMark] = React.useState(false);
  const [loadedCrypto, setLoadedCrypto] = React.useState(false);
  const [popped, setPopped] = React.useState(false);
  const [lookedUpKey, setLookedUpKey] = React.useState(false);
  const [useStored, setUseStored] = React.useState(false);

  const script_host = React.useRef(null);

  var id_match = useRouteMatch("/mid/:mark_id?");

  var css_match = useRouteMatch("/css/:mark_id?");

  var load_crypto = React.useCallback(
    async (input, callback) => {
      var script = document.createElement("script");
      script.async = true;
      script.src = "/openpgp.min.js";
      script.type = "text/javascript";
      script.id = "openpgp_script";
      script.onload = async () => {
        window.openpgp.config.versionstring = process.env.REACT_APP_BRAND;
        window.openpgp.config.commentstring = process.env.REACT_APP_BRAND;

        openpgp = window.openpgp;

        var script_sub = document.createElement("script");
        script_sub.async = true;
        script_sub.src = "/crypto/sha256.js";
        script_sub.type = "text/javascript";
        script_sub.id = "sha256_script";
        script_sub.onload = async () => {
          await openpgp
            .initWorker({ path: "/openpgp.worker.min.js" })
            .then(async (worker) => {
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let mark_id = params.get("mark_id");

              let pop = params.get("pop");
              var new_crypto = Object.assign({}, props.crypto);

              var stored = store.get("crypto");

              if (stored && !useStored && !pop) {
                setUseStored(true);
                //new_crypto = JSON.parse(stored)
                new_crypto.stored = true;
                //props.setCrypto(new_crypto);
              }

              if (!popped && pop) {
                var pk = store.get("private_key");
                var pubkey = store.get("public_key");
                var pp = store.get("passphrase");

                new_crypto.public_key_id = public_key_id;
                new_crypto.public_key = pubkey ? pubkey : public_key;
                new_crypto.private_key = pk ? pk : private_key;
                if (pp) {
                  new_crypto.passphrase = pp;
                }
                new_crypto.refresh_keys = true;
                new_crypto.refresh_creds = true;
                new_crypto.refresh_entry = true;
                new_crypto.refresh_passphrase = true;
                new_crypto.registry_entry = registry_entry;
                setPopped(true);

                var lookup_key = async () => {
                  //new_crypto.public_key_id = mark.public_key_id.trim();

                  let found = await axios.post("/lookupkey", {
                    key: public_key_id,
                  });

                  //new_crypto.lookupKey = public_key_id

                  if (found.data.key_id) {
                    //    new_crypto.lookupKeyFound = public_key_id
                    new_crypto.keyFound = found.data.key_id;
                    new_crypto.registry_entry = found.data.registry_entry;
                  } else {
                    new_crypto.keyNotFound = found.data.failed;
                    new_crypto.lookupKeyFound = null;
                  }
                  props.setCrypto(new_crypto);

                  setLookedUpKey(true);
                };

                if (!lookedUpKey) {
                  lookup_key();
                }
              }

              if (!mark_id) {
                var mark_id_token = id_match
                  ? id_match.params.mark_id
                    ? id_match.params.mark_id
                    : false
                  : false;

                if (mark_id_token) {
                  mark_id = mark_id_token;
                }

                if (!mark_id) {
                  mark_id_token = css_match
                    ? css_match.params.mark_id
                      ? css_match.params.mark_id
                      : false
                    : false;

                  mark_id = mark_id_token;
                }
              }

              if (!loadedMark && mark_id) {
                var load = async () => {
                  var ro = {};
                  ro.method = "GET";
                  ro.url = "/load_mark/" + mark_id;

                  var loaded_mark = await axios(ro);

                  var mark = loaded_mark.data.mark;

                  var stamp = loaded_mark.data.stamp;

                  await Crypto.verifyServerMessage(stamp, "verify", {
                    creation_time: loaded_mark.data.time_stamp,
                  }).then(async (result) => {
                    if (result.signed_by === public_key_id) {
                      props.setAlert({
                        open: true,
                        severity: "success",
                        message: "mark is authentic",
                        vertical: "bottom",
                        horizontal: "center",
                      });
                    } else {
                      props.setAlert({
                        open: true,
                        severity: "error",
                        message: "mark is not authentic",
                        vertical: "bottom",
                        horizontal: "center",
                      });

                      return false;
                    }
                  });

                  if (mark) {
                    var new_crypto = Object.assign({}, props.crypto);
                    //new_crypto.public_key_id = mark.public_key_id.trim();
                    new_crypto.qr = mark.qr;
                    new_crypto.sender = mark.public_key.trim();
                    new_crypto.mark_id = mark.mark_id.trim();
                    new_crypto.output = mark.output.trim();
                    new_crypto.lookupKey = mark.public_key_id.trim();
                    new_crypto.lookupMark = mark.mark_id;

                    var extracted = await Crypto.extract_id(
                      new_crypto.lookupKey
                    );

                    if (extracted) {
                      var key_lookupKey = extracted;
                    } else {
                      key_lookupKey = new_crypto.lookupKey;
                    }

                    let found = await axios.post("/lookupkey", {
                      key: key_lookupKey,
                    });

                    if (found.data.key_id) {
                      new_crypto.lookupKeyFound = found.data.key_id;
                      new_crypto.lookupKeyFingerprint =
                        found.data.key_fingerprint;
                    } else {
                      new_crypto.lookupKeyFound = null;
                      new_crypto.lookupKeyNotFound = found.data.failed;
                    }

                    var mark_found = await axios.get("/lookup/" + mark.mark_id);

                    if (mark_found.data.mark_id) {
                      new_crypto.lookupMark = mark_found.data.mark_id;
                      new_crypto.foundMark = mark_found.data.mark_id;
                    } else {
                      new_crypto.foundMark = "invalid";
                    }

                    new_crypto.refresh_output = true;
                    new_crypto.refresh_lookup_key = true;
                    //new_crypto.lookupKeyFound = mark.public_key_id.trim();

                    new_crypto.lookupKeyFingerprint =
                      mark.public_key_fingerprint;
                    new_crypto.refresh_id = true;

                    props.setCrypto(new_crypto);
                  }
                };

                setLoadedMark(true);
                load();
              }
            });
        };

        script_host.current.appendChild(script_sub);

        //document.body.appendChild(script);
      };

      script_host.current.appendChild(script);
    },
    [css_match, id_match, loadedMark, lookedUpKey, popped, props, useStored]
  );

  React.useEffect(() => {
    if (!loadedCrypto) {
      setLoadedCrypto(true);
      setTimeout(() => {
        load_crypto();
      }, 0);
    }
  }, [load_crypto, setLoadedCrypto, loadedCrypto]);

  return (
    <React.Fragment>
      <ThemeProvider theme={themes[props.theme]}>
        <CssBaseline />
        <Routes />
        <Confirm />
      </ThemeProvider>
      <div style={{ display: "none" }} ref={script_host}></div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  theme: state.theme,
  crypto: state.crypto,
  temp: state.temp,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setTheme: (theme) => dispatch(setTheme(theme)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
