import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { setAlert } from "../actions";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "100%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    faq: {
      margin: "0px !important",
      border: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: "0px",
      boxShadow: "none",
      "&:not(:first-child)": {
        borderTop: 0,
      },
      "&:before": {
        display: "none",
      },
      "&.Mui-expanded": {
        "&:not(:first-child)": {
          borderTop: "0px solid rgba(0, 0, 0, .125)",
        },
      },
    },
    between: {
      marginTop: "8px !important",
      marginBottom: "0px !important",
      paddingBottom: "8px",
      borderLeft: "0px",
      borderRight: "0px",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
    },
    summary: {
      minHeight: "48px !important",
    },
    content: {},
    divider: {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
    },
  }),
  { withTheme: true }
);

const faqs = [];
faqs.push(
  {
    title: `Submit a mark`,
    body: (
      <React.Fragment>
        1.) Ensure that the private key field contains a valid private key. This
        value will be automatically populated for you after you generate a new
        key pair, but if you want to use an existing key you have previously
        generated just populate the private key field manually with the private
        key you previously saved.
        <br />
        <br />
        2.) Ensure that the passphrase box contains the passphrase associated
        with the private key you are using. This is the passphrase you chose
        when generating the key pair.
        <br />
        <br />
        3.) Enter the content you want to mark into the "content" field.
        <br />
        <br />
        4.) (optional) Choose a file to attach to your mark. Files must be no
        larger than 1024 KB. You can submit any type of file as an attachment to
        your mark. If the content you want to mark <em>is</em> the file the
        "content" field must still contain a value. You can choose whatever
        value you want, such as a description of the file or some other
        information that you want the file attached to.
        <br />
        <br />
        5.) Click "Submit Mark"
        <br />
        <br />
        6.) Your mark will be submitted to the registry and identified by your
        public key. A timestamp will be attached to permanently record the time
        which you submitted the mark to the registry.
        <br />
        <br />
        7.) The page will display your registered mark along with links to view
        different formats and versions of the mark's "receipt". You can view or
        share these links at any time. A QR code will also appear that you can
        share. It will act as a direct link to your mark's public receipt page.
      </React.Fragment>
    ),
  },
  {
    title: `Lookup a mark`,
    body: (
      <React.Fragment>
        1.) Enter a mark id into the "blockmark id" field.
        <br />
        <br />
        2.) Click "Lookup"
        <br />
        <br />
        3.) If the mark id is valid a link will appear below the lookup box next
        to the words "mark found". Visit this link to view the details for the
        mark you have looked up.
      </React.Fragment>
    ),
  },
  {
    title: `Register a public key`,
    body: (
      <React.Fragment>
        If you want other people to be able to look up your public key in the{" "}
        {process.env.REACT_APP_BRAND} registry so that they can validate the
        sender of your signed messages you can submit your public key to the
        registry to create a registry entry. This entry can be looked up using
        your public key or public key id. Registry entries for public keys are
        submitted as marks just like any other content, but they have one
        additional section of information for "registry entry". When you
        register a public key it creates a mark with the content section of the
        mark and the regsitry entry section of the mark having the same value.
        You provide this value and it can contain any information that you want,
        such as a name, email address, phone number, a link to social media or
        other website, a link to another mark, a piece of signed and/or
        encrypted content, etc. You cannot attach a file to a registry entry,
        but you can submit a file with a separate mark and put the link ot that
        mark in your registry entry. You can create a new registry entry at any
        time and it will overwrite your previous entry with the new information.
        If you want to disable your existing registry entry then enter the
        following words into the "registry entry" field: <br />
        <br />
        <strong>no entry</strong>
        <br />
        <br />
        1.) Ensure that private key field contains a valid private key. This
        value will be automatically populated for you after you generate a new
        key pair, but if you want to use an existing key you have previously
        generated just populate the private key field manually with the private
        key you previously saved.
        <br />
        <br />
        2.) Ensure that public key field contains the public key associated with
        the private key you entered into the "private key" field. This value
        will be automatically populated for you after you generate a new key
        pair, but if you want to use an existing key you have previously
        generated just populate the public key field manually with the public
        key you previously saved that matches the private key you are using.
        <br />
        <br />
        3.) Ensure that the passphrase box contains the passphrase associated
        with the private key you are using. This is the passphrase you chose
        when generating the key pair.
        <br />
        <br />
        4.) Enter the information for your registry entry into the "registry
        entry" field.
        <br />
        <br />
        5.) Click "Register Public Key"
        <br />
        <br />
        6.) Your mark will be submitted to the registry and identified by your
        public key. A timestamp will be attached to permanently record the time
        which you submitted the mark to the registry. The content and registry
        entry sections of your registry entry will both containt the information
        you entered into the "registry entry" field. After a public key has been
        submitted to the registry its entry can be looked up by following the
        "Lookup a public key" instructions.
        <br />
        <br />
        7.) The page will display your registry entry as a registered mark along
        with links to view different formats and versions of the mark's
        "receipt". You can view or share these links at any time. A QR code will
        also appear that you can share. It will act as a direct link to your
        mark's public receipt page.
      </React.Fragment>
    ),
  },
  {
    title: `Lookup a public key`,
    body: (
      <React.Fragment>
        1.) Enter a public key or public key id into the "public key or public
        key id" field.
        <br />
        <br />
        2.) Click "Lookup Key"
        <br />
        <br />
        3.) If the public key is valid and a registry entry for that key has
        been submitted then a link will appear below the lookup box next to the
        words "key registered". Visit this link to view the registry entry for
        the key you have looked up.
      </React.Fragment>
    ),
  },
  {
    title: `Submit private / encrypted content as a mark`,
    body: (
      <React.Fragment>
        If you want to submit content as a mark but do not want that content to
        be publicly accessible you can encrypt whatever content you want to
        submit and then submit the encrypted content as your mark. This mark
        will behave like all other marks except that no one will be able to view
        the content unless they have access to the private key of the recipient
        the content was encrypted for. The mark's authenticity can still be
        verified while protecting the content until it is decrypted.
      </React.Fragment>
    ),
  },
  {
    title: `Use "spare keys" to allow encrypted marks to be decrypted by
        others`,
    body: (
      <React.Fragment>
        If the content of a mark is an encrypted value and you want others to be
        able to view it, there is a trick called "spare keys" that will allow
        you to share the private key protecting the content with any number of
        individuals without compromising your own keys or other recipient's
        keys.
        <br />
        <br />
        1.) Follow the "Generate a key pair" instructions found in the "How to
        use the tools" help section to generate a new set of "spare keys" that
        you can share, separate from your own key pair.
        <br />
        <br />
        2.) Enter the newly generated public key into the "recipient" field.
        <br />
        <br />
        3.) Enter your content into the "input" field.
        <br />
        <br />
        4.) Click "Encrypt"
        <br />
        <br />
        5.) The encrypted value of your content will appear in the "output"
        field.
        <br />
        <br />
        6.) Copy the encrypted value from the output field into the "content"
        field.
        <br />
        <br />
        7.) Follow the "Submit a mark" instructions.
        <br />
        <br />
        8.) Your mark will be created and its content will be encrypted. The
        content can be decrypted using the private key from the "spare keys" you
        generated.
        <br />
        <br />
        9.) Share the private "spare key" with anyone you want to be able to
        decrypt the content.
        <br />
        <br />
        10.) (optional) Share the private key publicly to allow anyone to view
        the encrypted content. This is useful if you want to mark something
        before you want others to be able to see it. An example of this is if
        you have a piece of content that is a work in progress, but you still
        want to mark it to document its timeline and you don't want others to be
        able to view it until you're ready to allow them to see it by releasing
        the private "spare key" to the general public.
        <br />
        <br />
        Basically, "spare keys" are keys that you can share with anyone who you
        want to be able to view the encrypted content without giving away your
        own keys or expecting them to share their keys with others. If you have
        encrypted content marked and you want to later allow the general public
        to view that content you can share the private "spare key" publicly
        without worry.
      </React.Fragment>
    ),
  }
);

function FAQ(props) {
  const [expanded, setExpanded] = React.useState({});

  const handleChange = (faq) => (event, isExpanded) => {
    var new_exp = {};

    if (expanded[faq]) {
      new_exp[faq] = false;
    } else {
      new_exp[faq] = true;
    }

    var final_exp = {};

    //comment out to allow multiple accordions to be open simultaneously
    final_exp = Object.assign({}, new_exp);

    final_exp = new_exp;

    //		console.log(final_exp);

    setExpanded(final_exp);
  };

  var classes = useStyles();

  var prev = "content";

  return (
    <div className={classes.root}>
      {faqs.map((f, i) => {
        if (f.body) {
          prev = "content";
          return (
            <Accordion
              expanded={expanded["faq" + i] ? expanded["faq" + i] : false}
              onChange={handleChange("faq" + i)}
              key={"faq" + i}
              TransitionProps={{
                timeout: 0,
              }}
              className={classes.faq + " faq subSection"}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"faq" + i + "bh-content"}
                id={"faq" + i + "bh-header"}
                className={classes.summary}
              >
                <Typography
                  className={classes.heading + " bold"}
                  variant="body2"
                >
                  {f.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant={"body2"} component="div">
                  {f.body}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        } else {
          prev = "divider";
          return (
            <div
              key={"faq" + i}
              className={
                "faq " + classes.between + " " + classes[prev] + " " + prev
              }
            >
              <Typography component={"p"} variant="body2">
                {f.title}
              </Typography>
            </div>
          );
        }
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
