import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { Button, TextField, Grid, Link } from "@material-ui/core";
//import Director from "../Director";
//import Redirector from "../Redirector";
import axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";

var use_theme = null;

const useStyles = makeStyles(
  (theme) => {
    use_theme = theme;

    return {
      lookup_form: {
        width: "100%",
      },
      tall_button: {
        height: "100%",
      },
    };
  },
  { withTheme: true }
);

function Home(props) {
  const classes = useStyles();
  
  const matches = use_theme.breakpoints.down("md");
  var isMed = useMediaQuery(matches);
  const [lookup, setLookup] = React.useState(props.crypto.lookupMark);

  var new_crypto = Object.assign({}, props.crypto);

  var setCrypto = props.setCrypto

  React.useEffect(() => {
    if (new_crypto.refresh_id) {    
      //setPassphrase(new_crypto.passphrase)
      setLookup(new_crypto.lookupMark)           
      new_crypto.refresh_id = false
      setCrypto(new_crypto);
    }
  }, [new_crypto,setCrypto,setLookup]);


  return (
    <form
      className={classes.lookup_form}
      onSubmit={async (e) => {
        e.preventDefault();

        var new_crypto = Object.assign({}, props.crypto);
        new_crypto.lookupMark = lookup;
        new_crypto.foundMark = "";

        if (!new_crypto.lookupMark || !new_crypto.lookupMark.length) {
          props.setAlert({
            open: true,
            severity: "error",
            message: "you must provide a valid mark id",
            vertical: "bottom",
            horizontal: "right",
          });
          return false;
        }

        let found = await axios.get("/lookup/" + new_crypto.lookupMark);

        if (found.data.mark_id) {
          new_crypto.foundMark = found.data.mark_id;
        } else {
          new_crypto.foundMark = "invalid";
        }

        props.setCrypto(new_crypto);

        //Redirector("/check/" + lookupMark);
        //Redirector("https://"+process.env.REACT_APP_DOMAIN+"/check/" + lookupMark);
      }}
    >
      <Grid
        container
        alignItems="flex-start"
        alignContent="flex-start"
        spacing={1}
        className={!isMed ? "topRow" : ""}
      >
        <Grid
          container
          spacing={1}
          item
          alignItems="stretch"
          direction="row"
          justify="center"
        >
          <Grid item xs={12} lg={9}>
            <TextField
              label={process.env.REACT_APP_BRAND + " id"}
              variant={process.env.REACT_APP_STYLE}
              fullWidth
              size="small"
              onChange={(e) => {
                var new_crypto = Object.assign({}, props.crypto);
                new_crypto.lookupMark = lookup;
                new_crypto.foundMark = "";
                props.setCrypto(new_crypto);

                setLookup(e.target.value);
                if (window.typing) {
                  clearTimeout(window.typing);
                }
                window.typing = setTimeout(() => {
                  props.setCrypto(new_crypto);
                }, 100);
              }}
              value={lookup}              
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Button
              className={classes.tall_button}
              color="primary"
              type="submit"
              variant="contained"
              //size={!isMed ? "large" : "large"}
              fullWidth
            >
              Lookup
            </Button>
          </Grid>
        </Grid>

        {props.crypto.foundMark && props.crypto.foundMark !== "invalid" ? (
          <Grid item xs={12}>
            mark found{" "}
            <Link
              href={
                "https://" +
                process.env.REACT_APP_DOMAIN +
                "/check/" +
                props.crypto.foundMark
              }
              target={"_" + props.crypto.foundMark}
            >
              {props.crypto.foundMark}
            </Link>
          </Grid>
        ) : props.crypto.foundMark ? (
          <Grid item xs={12}>
            <div>
              <Grid>
                <Grid item xs={12}>
                  {process.env.REACT_APP_BRAND} id not found
                </Grid>
              </Grid>
            </div>{" "}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <div>
              <Grid>
                <Grid item xs={12}>
                  enter a valid {process.env.REACT_APP_BRAND} id to lookup the
                  receipt for that mark
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  crypto: state.crypto,
  temp: state.temp,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
