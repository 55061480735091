import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto, setTheme } from "../actions";
import { Grid, Box, Typography } from "@material-ui/core";
import Lookup from "./Lookup";
import LookupKey from "./LookupKey";
import Generate from "./Generate";
//import Submit from "./Submit";
import File from "./File";
import Register from "./Register";
import Keys from "./Keys";
import Fields from "./Fields";
import Output from "./Output";
import Theme from "../ThemeSwitch";
import FAQ from "./FAQ";
import Credentials from "../Credentials";
//import Hash from "./Hash";
import Moment from "moment-timezone";
import useMediaQuery from "@material-ui/core/useMediaQuery";

var use_theme = null;

const useStyles = makeStyles(
  (theme) => {
    use_theme = theme;
    return {
      box: {
        width: "100%",
      },
      sub: {
        marginTop: theme.spacing(2),
      },
      next: {
        marginTop: theme.spacing(0.5),
      },
      qr: {
        width: "100%",
      },
      tall_button: {
        height: "100%",
      },
      title: {
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
          textAlign: "left",
        },
      },
      first_column: {
        order: 1,
        [theme.breakpoints.up("sm")]: {
          order: 1,
        },
      },
      second_column: {
        order: 2,
        [theme.breakpoints.up("sm")]: {
          order: 2,
        },
      },
      third_column: {
        order: 3,
        [theme.breakpoints.up("sm")]: {
          order: 3,
        },
      },
      fourth_column: {
        order: 4,
        [theme.breakpoints.up("sm")]: {
          order: 4,
        },
      },
      column_header: {
        marginTop: "0px",
        marginBottom: "0px",
        textAlign: "center",
        width: "100%",
      },
      column_headers: {
        display: "none",
        [theme.breakpoints.up("lg")]: {
          display: "block",
        },
      },
      row_header: {
        display: "block",
        [theme.breakpoints.up("lg")]: {
          display: "none",
        },
        marginBottom: "16px",
        marginTop: "0px",
        textAlign: "center",
      },
    };
  },
  { withTheme: true }
);

function Check(props) {
  const classes = useStyles();

  const matches = use_theme.breakpoints.up("lg");
  var isLg = useMediaQuery(matches);

  return (
    <React.Fragment>
      <Box p={1} pb={0}>
        <Grid
          className={classes.title}
          style={{
            margin: "0px",
            width: "100%",
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingTop: "4px",
          }}
        >
          <Typography
            variant="h5"
            style={{ textAlign: "center", marginBottom: "12px" }}
          >
            {process.env.REACT_APP_BRAND} secure registry
          </Typography>
          <div style={{ borderBottom: "1px solid", marginBottom: "8px" }} />
        </Grid>
      </Box>

      <Box p={0} pl={1} pr={1} pb={0} className={classes.column_headers}>
        <Grid container spacing={2} style={{ margin: "0px", width: "100%" }}>
          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            lg={3}
            className={classes.second_column}
          >
            <Typography variant="h6" className={classes.column_header}>
              PGP Key Tools
            </Typography>
          </Grid>

          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            lg={3}
            className={classes.third_column}
          >
            <Typography variant="h6" className={classes.column_header}>
              Registry Tools
            </Typography>
          </Grid>

          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            lg={4}
            className={classes.fourth_column}
          >
            <Typography variant="h6" className={classes.column_header}>
              Encryption & Signing Tools
            </Typography>
          </Grid>

          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            lg={2}
            className={classes.first_column}
          >
            <Typography variant="h6" className={classes.column_header}>
              Help & Instructions
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box p={0} pl={1} pr={1} pb={0}>
        <Grid container spacing={2} style={{ margin: "0px", width: "100%" }}>
          {/*
          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            md={3}
            spacing={1}
          ><div>

          {props.crypto.registeredKey ? (
            <Grid item xs={12}>
              <div className={classes.sub}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.crypto.registeredKey,
                  }}
                />
              </div>
            </Grid>
          ) : null}

          {props.crypto.signedMark ? (
            <Grid item xs={12}>
              <div className={classes.sub}>
                <div
                  dangerouslySetInnerHTML={{ __html: props.crypto.signedMark }}
                />
              </div>
            </Grid>
          ) : null}  
          {helpText}  
          </div></Grid>
          */}

          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            lg={3}
            className={classes.second_column}
          >
            <Grid
              container
              item
              alignItems="flex-start"
              alignContent="flex-start"
              xs={12}
              className={classes.row_header}
            >
              <Typography variant="h6">PGP Key Tools</Typography>
            </Grid>

            <div className={classes.box}>
              <Keys />
            </div>
            <div className={classes.next} style={{ width: "100%" }}>
              <Generate />
            </div>
            <div className={classes.sub + " " + classes.box}>
              <Register />

              <Grid container>
                <div
                  style={{
                    width: "100%",
                    marginTop: "16px",
                  }}
                >
                  <Grid item container xs={12}>
                    {/*
<div>
											{process.env.REACT_APP_BRAND} public key:
											<br />
											<Link
												href={
													"https://" +
													process.env.REACT_APP_DOMAIN +
													"/key/" +
													process.env.REACT_APP_PUBLIC_KEY_ID
												}
												target={
													"_" + process.env.REACT_APP_BRAND + "_public_key"
												}
											>
												{process.env.REACT_APP_DOMAIN}/key/
												{process.env.REACT_APP_PUBLIC_KEY_ID}
											</Link>
											<br />
											<br />
											{process.env.REACT_APP_BRAND} public key id:
											<br />
											{process.env.REACT_APP_PUBLIC_KEY_ID}
											<br />
											<br />
                      {process.env.REACT_APP_BRAND} public key fingerprint:
											<br />
											{process.env.REACT_APP_PUBLIC_KEY_FINGERPRINT}
											<br />
											<br />
											{process.env.REACT_APP_BRAND} public key signed registry entry:
											<br />
											<Link
												href={
													"https://" +
													process.env.REACT_APP_DOMAIN +
													"/sig/" +
													process.env.REACT_APP_PUBLIC_KEY_MARK_ID
												}
												target={
													"_" + process.env.REACT_APP_BRAND + "_public_key"
												}
											>
												{process.env.REACT_APP_DOMAIN}/sig/{process.env.REACT_APP_PUBLIC_KEY_MARK_ID}
											</Link>
										</div>
										*/}
                  </Grid>
                </div>
              </Grid>

              <Credentials />
            </div>
          </Grid>

          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            lg={3}
            className={classes.third_column}
          >
            <Grid
              container
              item
              alignItems="flex-start"
              alignContent="flex-start"
              xs={12}
              className={classes.row_header}
            >
              <Typography variant="h6">Registry Tools</Typography>
            </Grid>

            <Grid item xs={12}>
              <Lookup />
            </Grid>
            <Grid item xs={12}>
              <File />
            </Grid>

            <Grid item xs={12}>
              <LookupKey />
            </Grid>

            {/*
              <div className={classes.sub}>
                <Hash />
              </div>
    */}
          </Grid>
          <Grid container item xs={12} lg={4} className={classes.fourth_column}>
            <Grid
              container
              item
              alignItems="flex-start"
              alignContent="flex-start"
              xs={12}
              className={classes.row_header}
            >
              <Typography variant="h6">Encryption & Signing Tools</Typography>
            </Grid>

            <Box mt={0} style={{ width: "100%" }}>
              <Fields />

              <div className={classes.sub}>
                <Output />
              </div>
            </Box>
          </Grid>

          <Grid
            container
            item
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            lg={2}
            spacing={0}
            className={classes.first_column}
          >
            <Grid container>
              <Grid item xs={12} sm={12} lg={12}>
                {(!props.crypto.registeredKey &&
                  !props.crypto.signedMark &&
                  props.crypto.mark_id &&
                  props.crypto.qr) ||
                ((props.crypto.registeredKey || props.crypto.signedMark) &&
                  props.crypto.mark_id &&
                  props.crypto.qr &&
                  isLg) ? (
                  <React.Fragment>
                    <div style={{ width: "100%" }}>
                      {props.crypto.qr ? (
                        <img
                          src={props.crypto.qr}
                          className={classes.qr}
                          alt={"qr code for " + props.crypto.mark_id}
                        />
                      ) : null}
                    </div>

                    <div
                      style={{
                        fontFamily: "monospace",
                        textAlign: "center",
                        width: "100%",
                        marginBottom: "8px",
                      }}
                    >
                      <a
                        href={
                          "https://" +
                          process.env.REACT_APP_DOMAIN +
                          "/check/" +
                          props.crypto.mark_id
                        }
                        target={"_" + props.crypto.mark_id}
                      >
                        {props.crypto.mark_id}
                      </a>
                    </div>
                  </React.Fragment>
                ) : null}

                <div
                  style={{ marginTop: props.crypto.mark_id ? "16px" : "0px" }}
                >
                  <Grid
                    container
                    item
                    alignItems="flex-start"
                    alignContent="flex-start"
                    xs={12}
                    className={classes.row_header}
                  >
                    <Typography variant="h6">Help & Instructions</Typography>
                  </Grid>

                  <FAQ />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box mt={0} p={2} pt={1}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Theme />
          <br />
          <a href={"mailto:webmaster@" + process.env.REACT_APP_DOMAIN}>
            webmaster@{process.env.REACT_APP_DOMAIN}
          </a>
          <br />
          <br />
          b-{process.env.REACT_APP_PUBLIC_KEY_TIMESTAMP}-m{" "}
          <a
            href={
              "https://" +
              process.env.REACT_APP_DOMAIN +
              "/key/" +
              process.env.REACT_APP_PUBLIC_KEY_ID
            }
            target="_blockmark_mark"
          >
            {process.env.REACT_APP_PUBLIC_KEY_ID}
          </a>
          <br />
          &#169; 2020-{Moment().year()} {process.env.REACT_APP_BRAND}. All
          rights reserved.
        </div>
      </Box>

      {props.crypto.signedMark || props.crypto.registeredKey ? (
        <Box mt={1} p={2} pt={0}>
          {props.crypto.registeredKey ? (
            <Grid item xs={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.crypto.registeredKey,
                }}
              />
            </Grid>
          ) : null}

          {props.crypto.signedMark ? (
            <Grid item xs={12}>
              <div
                dangerouslySetInnerHTML={{ __html: props.crypto.signedMark }}
              />
            </Grid>
          ) : null}
        </Box>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
  theme: state.theme,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  setTheme: (theme) => dispatch(setTheme(theme)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Check);
