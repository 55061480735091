import React from "react";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { TextField, Grid } from "@material-ui/core";
import Crypto from "../Crypto";
import axios from "axios";
//import Copy from "./Copy";
//import store from "store"

function Generate(props) {
  const [privateKey, setPrivateKey] = React.useState(props.crypto.private_key);
  const [publicKey, setPublicKey] = React.useState(props.crypto.public_key);

  var new_crypto = Object.assign({}, props.crypto);

  var setCrypto = props.setCrypto;

  React.useEffect(() => {
    if (new_crypto.refresh_keys) {
      setPublicKey(new_crypto.public_key);
      setPrivateKey(new_crypto.private_key);
      new_crypto.refresh_keys = false;
      setCrypto(new_crypto);
    }
  }, [new_crypto, setCrypto, setPublicKey, setPrivateKey]);

  var recheck_key = React.useCallback(async () => {
    var new_crypto = Object.assign({}, props.crypto);

    new_crypto.notFound = "";
    new_crypto.keyFound = "";
    setCrypto(new_crypto);

    var extracted = await Crypto.extract_id(props.crypto.public_key.trim());

    let found = await axios.post("/lookupkey", {
      key: extracted ? extracted : props.crypto.public_key,
    });

    new_crypto = Object.assign({}, props.crypto);

    if (found.data.key_id) {
      new_crypto.registry_entry = found.data.registry_entry;
      new_crypto.refresh_entry = true;
      new_crypto.public_key_id = found.data.key_id;
      new_crypto.keyFound = found.data.key_id;
      new_crypto.notFound = "";
    } else {
      new_crypto.notFound = found.data.failed;
      new_crypto.keyFound = "";
    }

    var extract = await Crypto.extract();

    if (extract === false && new_crypto.public_key.length) {
      new_crypto.notFound = "invalid key";
      new_crypto.public_key_id = "";
    } else if (extract === false) {
      new_crypto.notFound = "";
      new_crypto.public_key_id = "";
    }

    if (extracted) {
      new_crypto.public_key_id = extracted;
    }

    setCrypto(new_crypto);
  }, [props.crypto, setCrypto]);

  var setTemp = props.setTemp;

  React.useEffect(() => {
    if (props.temp.refresh_key) {
      recheck_key();
      var new_temp = Object.assign({}, props.temp);
      new_temp.refresh_key = false;
      setTemp(new_temp);
    }
  }, [props.temp, setTemp, recheck_key]);

  return (
    <Grid container alignItems="center" spacing={1}>
      {/*
        <Grid item xs={12}>

       <TextField
              label="public key id"
              variant={process.env.REACT_APP_STYLE}
              fullWidth                          
              size="small"
              onChange={(e) => {
                //var new_crypto = Object.assign({}, props.crypto)
                //new_crypto.public_key_id = e.target.value
                //props.setCrypto(new_crypto)
              }}
              value={props.crypto.public_key_id}              
              type="text"
            />
            </Grid>*/}

      <Grid
        item
        xs={12}
        md={props.public === false ? 12 : 6}
        style={{ position: "relative" }}
      >
        <TextField
          label="private key"
          variant={process.env.REACT_APP_STYLE}
          fullWidth
          multiline
          rows={5}
          size="small"
          onChange={(e) => {
            var new_crypto = Object.assign({}, props.crypto);
            new_crypto.private_key = e.target.value;
            new_crypto.stored = false;
            new_crypto.changed = true;

            setPrivateKey(e.target.value);
            if (window.typing) {
              clearTimeout(window.typing);
            }
            window.typing = setTimeout(() => {
              //store.remove('crypto')
              props.setCrypto(new_crypto);
            }, 250);
          }}
          value={privateKey}
          type="text"
          InputProps={{
            className: "topRow",
          }}
        />
      </Grid>
      {props.public === false ? null : (
        <Grid item xs={12} md={6} style={{ position: "relative" }}>
          <TextField
            label="public key"
            variant={process.env.REACT_APP_STYLE}
            fullWidth
            multiline
            rows={5}
            size="small"
            onChange={async (e) => {
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.registry_entry = "";
              new_crypto.public_key = e.target.value;
              new_crypto.stored = false;
              new_crypto.changed = true;
              new_crypto.refresh_entry = true;

              setPublicKey(e.target.value);
              if (window.typing) {
                clearTimeout(window.typing);
              }
              window.typing = setTimeout(() => {
                //store.remove('crypto')
                props.setCrypto(new_crypto);

                var new_temp = Object.assign({}, props.temp);
                new_temp.refresh_key = true;
                props.setTemp(new_temp);
              }, 250);
            }}
            value={publicKey}
            type="text"
            InputProps={{
              className: "topRow",
            }}
          />
          {/*<Copy value={publicKey} styles={{position: 'absolute', top: '0px', right: '0px'}} />*/}
        </Grid>
      )}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Generate);
