import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setUser, setAlert, setTemp, setCrypto } from "../actions";
import { Button, TextField, Grid } from "@material-ui/core";
import Crypto from "../Crypto";
import Moment from "moment-timezone";

const useStyles = makeStyles(
  (theme) => ({
    lookup_form: {
      width: "100%",
    },
    box: {
      marginTop: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(1),
    },
    sub: {
      marginTop: theme.spacing(2),
    },
    button_container: {
      marginTop: theme.spacing(0.5),
    },
    tall_button: {
      height: "100%",
    },
  }),
  { withTheme: true }
);

function Generate(props) {
  const classes = useStyles();

  const [input, setInput] = React.useState(props.crypto.input);
  const [recipient, setRecipient] = React.useState(props.crypto.recipient);
  //  const lookup = React.useRef(null);

  //  const lookup = React.useRef(null);

  var new_crypto = Object.assign({}, props.crypto);

  var setCrypto = props.setCrypto;

  React.useEffect(() => {
    if (new_crypto.refresh_input) {
      //setPassphrase(new_crypto.passphrase)

      setInput(new_crypto.input);
      new_crypto.refresh_input = false;
      setCrypto(new_crypto);
    }
  }, [new_crypto, setCrypto, setInput]);

  return (
    <React.Fragment>
      <Grid container alignItems="center" spacing={1}>
        {/*
        <Grid item xs={12}>

       <TextField
              label="public key id"
              variant={process.env.REACT_APP_STYLE}
              fullWidth                          
              size="small"
              onChange={(e) => {
                //var new_crypto = Object.assign({}, props.crypto)
                //new_crypto.public_key_id = e.target.value
                //props.setCrypto(new_crypto)
              }}
              value={props.crypto.public_key_id}              
              type="text"
            />
            </Grid>*/}

        <Grid item xs={12} md={6}>
          <TextField
            label="input"
            variant={process.env.REACT_APP_STYLE}
            fullWidth
            multiline
            rows={5}
            size="small"
            onChange={(e) => {
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.input = e.target.value;
              new_crypto.verified = null;
              new_crypto.decrypted = "";
              new_crypto.refresh_output = true;
              new_crypto.output = "";

              setInput(e.target.value);
              if (window.typing) {
                clearTimeout(window.typing);
              }
              window.typing = setTimeout(() => {
                props.setCrypto(new_crypto);
              }, 100);
            }}
            value={input}
            type="text"
            InputProps={{
              className: "topRow",
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="recipient"
            variant={process.env.REACT_APP_STYLE}
            fullWidth
            multiline
            rows={5}
            size="small"
            onChange={(e) => {
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.recipient = e.target.value;

              setRecipient(e.target.value);
              if (window.typing) {
                clearTimeout(window.typing);
              }
              window.typing = setTimeout(() => {
                props.setCrypto(new_crypto);
              }, 100);
            }}
            value={recipient}
            type="text"
            InputProps={{
              className: "topRow",
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        className={classes.button_container}
        alignItems="stretch"
        direction="row"
        justify="center"
      >
        <Grid item xs={6} md={3}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            className={classes.tall_button}
            onClick={async () => {
              var new_crypto = Object.assign({}, props.crypto);

              if (!new_crypto.input) {
                props.setAlert({
                  open: true,
                  severity: "error",
                  message: "cannot sign empty input",
                  vertical: "bottom",
                  horizontal: "center",
                });
                return false;
              }

              var creation_time = Moment().milliseconds(0).valueOf();

              var signed = await Crypto.signMessage(props.crypto.input, false, {
                creation_time: creation_time,
              });
              new_crypto = Object.assign({}, props.crypto);
              new_crypto.output = signed.trim();
              new_crypto.refresh_output = true;
              new_crypto.decrypted = null;
              new_crypto.sender = new_crypto.public_key;
              props.setCrypto(new_crypto);
            }}
            disabled={!props.crypto.private_key ? true : false}
          >
            Sign
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            className={classes.tall_button}
            onClick={async () => {
              var encrypted = await Crypto.encryptPublic(props.crypto.input);
              var new_crypto = Object.assign({}, props.crypto);
              new_crypto.output = encrypted.trim();
              new_crypto.verified = null;
              new_crypto.refresh_output = true;
              new_crypto.sender = new_crypto.public_key;
              props.setCrypto(new_crypto);
            }}
            disabled={
              !props.crypto.recipient || !props.crypto.input ? true : false
            }
          >
            Encrypt
          </Button>
        </Grid>

        <Grid item xs={6} md={3}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            onClick={async () => {
              var creation_time = Moment().milliseconds(0).valueOf();

              var signed = await Crypto.signMessage(props.crypto.input, false, {
                creation_time: creation_time,
              });

              var new_crypto = Object.assign({}, props.crypto);
              var encrypted = await Crypto.encryptPublic(signed.trim());
              new_crypto.output = encrypted.trim();
              new_crypto.decrypted = null;
              new_crypto.refresh_output = true;
              new_crypto.sender = new_crypto.public_key;
              props.setCrypto(new_crypto);
            }}
            disabled={
              !props.crypto.private_key ||
              !props.crypto.recipient ||
              !props.crypto.input
                ? true
                : false
            }
          >
            Sign >> Encrypt
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            onClick={async () => {
              var encrypted = await Crypto.encryptPublic(props.crypto.input);
              var new_crypto = Object.assign({}, props.crypto);
              var creation_time = Moment().milliseconds(0).valueOf();

              var signed = await Crypto.signMessage(encrypted.trim(), false, {
                creation_time: creation_time,
              });
              new_crypto.output = signed.trim();
              new_crypto.verified = null;
              new_crypto.refresh_output = true;
              new_crypto.sender = new_crypto.public_key;
              props.setCrypto(new_crypto);
            }}
            disabled={
              !props.crypto.private_key ||
              !props.crypto.recipient ||
              !props.crypto.input
                ? true
                : false
            }
          >
            Encrypt >> Sign
          </Button>
        </Grid>
        <Grid item xs={6} md={6}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            onClick={async () => {
              var new_crypto = Object.assign({}, props.crypto);
              var encrypted_signed = await Crypto.encrypt(
                props.crypto.input
              ).catch((err) => {
                props.setAlert({
                  open: true,
                  severity: "error",
                  message: err,
                  vertical: "bottom",
                  horizontal: "center",
                });
                return false;
              });

              if (!encrypted_signed) {
                return false;
              }

              if (encrypted_signed.extended_message) {
                new_crypto.input = encrypted_signed.extended_message;
              }

              new_crypto.output = encrypted_signed.encrypted.trim();
              new_crypto.sender = new_crypto.public_key;
              new_crypto.refresh_output = true;
              props.setCrypto(new_crypto);
            }}
            disabled={
              !props.crypto.private_key ||
              !props.crypto.recipient ||
              !props.crypto.input
                ? true
                : false
            }
          >
            Encrypt + Sign
          </Button>
        </Grid>
        <Grid item xs={6} md={6}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            className={classes.tall_button}
            onClick={async () => {
              var new_crypto = Object.assign({}, props.crypto);

              if (!new_crypto.input) {
                props.setAlert({
                  open: true,
                  severity: "error",
                  message: "cannot sign empty input",
                  vertical: "bottom",
                  horizontal: "center",
                });
                return false;
              }

              var creation_time = Moment().milliseconds(0).valueOf();

              var signature = await Crypto.signDetached(
                props.crypto.input,
                creation_time
              );
              new_crypto = Object.assign({}, props.crypto);
              new_crypto.signature = signature.signed;
              new_crypto.output = signature.signed;
              if (signature.extended_message) {
                new_crypto.extended_message = signature.extended_message;
                new_crypto.input = signature.extended_message;
              }
              new_crypto.sender = new_crypto.public_key;
              new_crypto.refresh_output = true;
              props.setCrypto(new_crypto);
            }}
            disabled={!props.crypto.private_key ? true : false}
          >
            Sign Detached
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Button
            color="primary"
            className={classes.tall_button}
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            onClick={async () => {
              var new_crypto = Object.assign({}, props.crypto);
              var hash = await Crypto.hash(new_crypto.recipient);

              var extended_message =
                props.crypto.input.trim() +
                "\r\n\r\nSHA-256 hash of original intended recipient's public key:\r\n" +
                hash;
              new_crypto.refresh_output = true;
              new_crypto.refresh_input = true;
              new_crypto.input = extended_message;
              props.setCrypto(new_crypto);
            }}
            disabled={!props.crypto.recipient ? true : false}
          >
            add recipient id
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            size="small"
            fullWidth
            onClick={async () => {
              var new_crypto = Object.assign({}, props.crypto);
              var hash = await Crypto.hash(new_crypto.recipient);
              var encrypted_signed = await Crypto.encrypt(
                props.crypto.input,
                hash
              );

              if (encrypted_signed.extended_message) {
                new_crypto.input = encrypted_signed.extended_message;
              }

              new_crypto.output = encrypted_signed.encrypted.trim();
              new_crypto.sender = new_crypto.public_key;
              new_crypto.refresh_output = true;
              new_crypto.refresh_input = true;
              props.setCrypto(new_crypto);
            }}
            disabled={
              !props.crypto.recipient || !props.crypto.private_key
                ? true
                : false
            }
          >
            add recipient id >> Encrypt + Sign
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  temp: state.temp,
  crypto: state.crypto,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setTemp: (temp) => dispatch(setTemp(temp)),
  setCrypto: (crypto) => dispatch(setCrypto(crypto)),
  changeCrypto: (callback, crypto) => {
    dispatch((dispatch) => {
      callback(dispatch(setCrypto(crypto)).crypto);
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Generate);
